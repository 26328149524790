import React from "react";
import useSWR from "swr";
import fetch from "../services/fetch";
import ServerError from "./ServerError";
import Loading from "./Loading";
import TableView from "./TableView";

export default function Viewer ({getDataUrl, setTicker, setSector, setIndustry}: {
    getDataUrl: () => string,
    setTicker: (ticker: string) => void,
    setSector: (sector: string) => void,
    setIndustry: (industry: string) => void
}) {

    const {data: tableConfig, error: configError} = useSWR(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/t2config`, fetch, {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshWhenOffline: false,
            refreshWhenHidden: false,
            refreshInterval: 0
        }
    );

    if (configError) {
        return <ServerError/>
    }

    if (!tableConfig) {
        return <Loading/>
    }

    return (
        <TableView tableConfig={tableConfig}
                   getDataUrl={getDataUrl}
                   setTicker={setTicker}
                   setSector={setSector}
                   setIndustry={setIndustry}/>
    )
}
