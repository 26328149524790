import React from "react";
import './styles/App.scss';
import {
    HashRouter as Router,
} from "react-router-dom";
import Content from "./components/Content";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";


export default function App() {

    return (
        <Router>
            <Sidebar/>
            <div className="main">
                <Header/>
                <Content/>
            </div>
            <Footer/>
        </Router>
    );
}

