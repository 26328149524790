
export function millify(num: number, maximumFractionDigits: number) {
    let si = [
        {value: 1E12, symbol: "T"},
        {value: 1E9, symbol: "B"},
        {value: 1E6, symbol: "M"},
        {value: 1E3, symbol: "k"}
    ], i: number;
    for (i = 0; i < si.length; i++) {
        if (Math.abs(num) >= si[i].value) {
            return (num / si[i].value).toFixed(maximumFractionDigits).replace(
                /\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].symbol;
        }
    }
    return num.toString();
}
