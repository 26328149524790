import React, {useEffect, useRef, useState} from 'react';
import DataTable from "./DataTable";
import {millify} from "../utils/millify";
import Loading from "./Loading";

const SIZE = 200;

export default function TableView({getDataUrl, setTicker, setSector, setIndustry, tableConfig}: {
    getDataUrl: () => string,
    setTicker: (ticker: string) => void,
    setSector: (sector: string) => void,
    setIndustry: (industry: string) => void,
    tableConfig: any
}) {
    const [sort, setSort] = useState({key: '', ascending: true});
    const [data, setData] = useState();
    const [isFetching, setIsFetching] = useState(false);
    const [tableHeight, setTableHeight] = useState(window.innerHeight - SIZE);
    const infoRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const url = sort.key ? `${getDataUrl()}&sb=${sort.key}&asc=${sort.ascending}` : getDataUrl();
        fetch(url)
            .then(r => r.json())
            .then(d => {
                // console.log(data);
                setData(d);
            })
            .catch((err: any) => {
                console.log(err);
            });
    }, [getDataUrl, sort.ascending, sort.key])

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    if (!data) {
        return <p>No data</p>
    }

    function handleScroll(e: any) {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            if (isFetching) {
                return;
            }
            fetchMoreData();
        }
    }

    function fetchMoreData() {
        if (data && data.next) {
            setIsFetching(true);
            fetch(data.next)
                .then(r => r.json())
                .then(moreData => {
                    const newData = {
                        count: data.count,
                        data: data.data.concat(moreData.data),
                        next: moreData.next,
                    };
                    setData(newData);
                    if (infoRef) {
                        const element = infoRef.current;
                        if (element) {
                            element.scrollIntoView(false);
                        }
                    }
                    setIsFetching(false);
                });
        }
    }

    function handleSort(column: any) {
        if (column.id === sort.key) {
            if (sort.ascending === column.ascending) {
                setSort({...sort, ascending: !column.ascending});
            } else {
                setSort({...sort, key: ''});
            }
        } else {
            setSort({...sort, key: column.id, ascending: column.ascending});
        }
    }

    function getColorCode(rank: number) {
        let nbBins = 6;
        for (let i = 0; i < nbBins; i++) {
            if (rank <= (i + 1) / nbBins) {
                return i;
            }
        }
    }

    function getClassName(row: any, column: any) {
        let classes: string[];
        classes = [];
        const rankField: string = column.id + '__rank__';
        if (column.type === 'number') {
            if (rankField in row) {
                let rankLabel = getColorCode(row[rankField]);
                classes.push(row[column.id] ? `green-to-red-${rankLabel}` : '');
            }
            classes.push('right-aligned');
        }
        return classes.join(' ');
    }

    function renderCell(row: any, column: any) {
        const cellValue = row[column.id];
        switch (column.type) {
            case 'datetime':
                return new Date(cellValue).toLocaleString('en-US',
                    {day: 'numeric', month: 'short', year: 'numeric', timeZone: 'UTC'});

            case 'link':
                return isClickable(row, column)
                    ?
                    <span className="link" onClick={e => onCellClick(row, column)}>{row[column.id]}</span>
                    :
                    row[column.id]

            case 'number':
                const num = parseFloat(cellValue);
                if (isNaN(num)) return '';
                let formattedNum;
                switch (column.format) {
                    case '%':
                        formattedNum = (num / 100).toLocaleString('en-US', {
                            style: 'percent',
                            maximumFractionDigits: 2
                        });
                        break;
                    case '$':
                        const currencyField = column.id + '__currency__';
                        formattedNum = num.toLocaleString('en-US', {
                            style: 'currency',
                            currency: currencyField in row ? row[currencyField] : 'USD',
                            maximumFractionDigits: 2
                        });
                        break;
                    case 'M':
                        formattedNum = '$' + millify(num, 2);
                        break;
                    default:
                        formattedNum = num.toLocaleString('en-US',
                            {
                                maximumFractionDigits: 2
                            }
                        );
                }
                return num > 0 ? formattedNum : <span>({formattedNum})</span>
            default:
                return cellValue;
        }
    }

    function handleWindowResize() {
        setTableHeight(window.innerHeight - SIZE);
    }

    function renderTh(index: number, column: any) {
        return (
            <th key={`th-${index}`}
                onClick={e => handleSort(column)}
                className={index === 0 ? 'sticky symbol' : 'sticky'}
            >
                {column.id === sort.key && <i className={sort.ascending ? 'asc' : 'desc'}/>}
                <span>{column.header ? column.header : column.id}</span>
            </th>
        )
    }

    function renderTd(rowIndex: number, cellIndex: number, row: any, column: any) {
        return (
            cellIndex === 0 ?
                <th key={`${rowIndex}-${cellIndex}`}
                    className={cellIndex === 0 ? 'sticky first-col ' : '' + getClassName(row, column)}
                >
                    {renderCell(row, column)}
                </th>
                :
                <td key={`${rowIndex}-${cellIndex}`}
                    className={cellIndex === 0 ? 'sticky first-col ' : '' + getClassName(row, column)}
                >
                    {renderCell(row, column)}
                </td>

        )
    }

    function isClickable(row: any, column: any) {
        if (column.id === 'name' && row['_website']) return true;
        return ['sector', 'industry', 'symbol'].includes(column.id);
    }

    function onCellClick(row: any, column: any) {
        if (column.id === 'sector') {
            setTicker('');
            setSector(row['sector']);
            return;
        }
        if (column.id === 'industry') {
            setTicker('');
            setSector(row['sector']);
            setIndustry(row['industry']);
            return;
        }
        if (column.id === 'symbol') {
            const url = 'https://finance.yahoo.com/quote/' + row['symbol'];
            window.open(url);
        }
        if (column.id === 'name') {
            if (row['_website']) {
                window.open(row['_website']);
            }
        }
    }

    return (
        <div>
            <div className="table-wrapper" onScroll={handleScroll} style={{height: tableHeight}}>
                <DataTable
                    columns={tableConfig.columns}
                    data={data}
                    renderTh={renderTh}
                    renderTd={renderTd}
                />
            </div>
            <p ref={infoRef} style={{fontSize: 'small'}}>
                Showing <b>{data.data.length}</b> rows of {data.count}
            </p>
            {isFetching ? <Loading/> : null}
        </div>
    )
}
