import React from 'react';

export default function () {
    return (
        <div className={'center'}>
            <br/>
            <img width="100px" src='./suicide.png' alt="Error"/>
            <p>Could not connect to server.</p>
        </div>
    )
}
