import React from 'react';
import useModal from "../services/useModal";
import Modal from "./Modal";
import Terms from "./Terms";

export default function () {
    const {isShowing, toggle} = useModal();

    return (
        <div className="footer1">
            <Modal isShowing={isShowing} hide={toggle}>
                <Terms/>
            </Modal>
            <footer>
                <p>Please read <button className="link" onClick={toggle}>Terms of Service</button> before use. <br/>
                    Copyright &copy;2020 <span style={{color: "black"}}>
                        <b>Stock</b></span><span style={{color: "red"}}><b>Recom</b></span>.com.
                    All rights reserved.</p>
            </footer>
        </div>
    )
}
