import React from 'react';

export default function () {
    return (
        <div>
            <h3>Terms of service</h3>
            <p><b>Disclaimer of Warranties/Accuracy and Use of Data</b></p>
            <p>
                Although the data found here has been produced and processed from third party sources believed to be
                reliable, no warranty expressed or implied is made regarding accuracy, adequacy, completeness,
                legality, reliability or usefulness of any information.
            </p>
            <p className={'right'}>StockRecom.com</p>
        </div>
    )
}
