import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleLeft, faBars} from "@fortawesome/free-solid-svg-icons";

export default function () {
    const [isOpen, setIsOpen] = useState(false)

    const handleTrigger = () => setIsOpen(!isOpen)

    return (
        <div className={`sidebar${isOpen ? ' sidebar--open' : ''}`}>
            <div className="trigger" onClick={handleTrigger}>
                <FontAwesomeIcon icon={isOpen ? faArrowCircleLeft : faBars}/>
            </div>
            {/*<Link to="/fundamental-analysis">*/}
            {/*    <div className="sidebar-position">*/}
            {/*        <FontAwesomeIcon icon={faUniversity}/>*/}
            {/*        <span className={`${isOpen ? 'visible' : 'hidden'}`} onClick={handleTrigger}>Fundamental Analysis</span>*/}
            {/*    </div>*/}
            {/*</Link>*/}
            {/*<Link to="/price-range-analysis">*/}
            {/*    <div className="sidebar-position">*/}
            {/*        <FontAwesomeIcon icon={faChartLine}/>*/}
            {/*        <span className={`${isOpen ? 'visible' : 'hidden'}`} onClick={handleTrigger}>Price Range Analysis</span>*/}
            {/*    </div>*/}
            {/*</Link>*/}
            {/*<Link to="/settings" onClick={handleTrigger}>*/}
            {/*    <div className="sidebar-position">*/}
            {/*        <FontAwesomeIcon icon={faCogs}/>*/}
            {/*        <span>Menu item 2</span>*/}
            {/*    </div>*/}
            {/*</Link>*/}
            {/*<div className="sidebar-position">*/}
            {/*    <FontAwesomeIcon icon={faList}/>*/}
            {/*    <span>Position 4</span>*/}
            {/*</div>*/}
        </div>
    )
}
