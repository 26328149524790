import React from 'react';

type DataTableProps = {
    columns: any,
    data: any,
    renderTh: (index: number, column: any) => React.ReactNode,
    renderTd: (rowIndex: number, cellIndex: number, row: any, column: any) => React.ReactNode
}


export default function ({columns, data, renderTh, renderTd}: DataTableProps) {
    return (
        <table>
            <thead>
            <tr>
                {
                    columns && columns.map((
                        column: any, index: number) => column.hidden ? null : renderTh(index, column)
                    )
                }
            </tr>
            </thead>
            <tbody>
            {
                data.data.map((row: any, rowIndex: number) =>
                    <tr key={rowIndex}>
                        {columns.map((column: any, cellIndex: number) => column.hidden ? null : renderTd(
                            rowIndex, cellIndex, row, column))}
                    </tr>
                )
            }
            </tbody>
        </table>
    )
}

