import React from "react";
import {Route, Switch} from "react-router-dom";
import Filter from "./Filter";
import useSWR from "swr";
import fetch from "../services/fetch";
import ServerError from "./ServerError";
import Loading from "./Loading";
// import InstrumentHistoryChart from "./InstrumentHistoryChart";
// import QueryBuilder1 from "./QueryBuilder1";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faChartLine, faUniversity} from "@fortawesome/free-solid-svg-icons";


export default function () {

    const swrConfig = {
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
        refreshWhenOffline: false,
        refreshWhenHidden: false,
        refreshInterval: 0
    };

    const {data: tickers, error: tickersError} = useSWR(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/tickers`, fetch, swrConfig);
    const {data: sectors, error: sectorsError} = useSWR(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/sectors`, fetch, swrConfig);

    if (sectorsError || tickersError) {
        return <ServerError/>
    }

    if (!sectors || !tickers) {
        return <Loading/>
    }

    return (
        <Switch>
            {/*<Route path="/fundamental-analysis">*/}
            {/*    <div className='center'>*/}
            {/*        <h2 className='section-title'>*/}
            {/*            <span><FontAwesomeIcon icon={faUniversity}/></span>*/}
            {/*            {'   '}Fundamental Analysis*/}
            {/*        </h2>*/}
            {/*        <QueryBuilder/>*/}
            {/*    </div>*/}
            {/*</Route>*/}
            {/*<Route path="/price-range-analysis">*/}
            {/*    <div className='center'>*/}
            {/*        <h2 className='section-title'><span><FontAwesomeIcon icon={faChartLine}/></span>{'   '}Price Range*/}
            {/*            Analysis</h2>*/}
            {/*        <QueryBuilder1/>*/}
            {/*    </div>*/}
            {/*</Route>*/}
            <Route path="/">
                <Filter tickers={tickers} sectors={sectors}/>
                {/*<InstrumentHistoryChart/>*/}
            </Route>
        </Switch>
    )
}


