import React from 'react';

export default function () {
    return (
        <div className="colorCodes">
            <span className="box green-to-red-0"/>
            <span className="box green-to-red-1"/>
            <span className="box green-to-red-2"/>
            <span className="box green-to-red-3"/>
            <span className="box green-to-red-4"/>
            <span className="box green-to-red-5"/>
        </div>
    );
}
