import React, {useState} from "react";
import ColorCodes from "./ColorCodes";
import TickerSearch from "./TickerSearch";
import {Suggestion} from "../types/Suggestion";
import Viewer from "./Viewer";


export default function ({tickers, sectors}: any) {
    const [ticker, setTicker] = useState('');
    const [sector, setSector] = useState('');
    const [industry, setIndustry] = useState('');

    function sectorChanged(e: React.ChangeEvent<HTMLSelectElement>) {
        setSector(e.target.value);
        setIndustry('');
    }

    function industryChanged(e: React.ChangeEvent<HTMLSelectElement>) {
        setIndustry(e.target.value);
    }


    function handleTickerSelected(suggestion: Suggestion) {
        setTicker(suggestion ? suggestion.symbol : '');
        setSector(suggestion ? suggestion.sector : '');
        setIndustry(suggestion ? suggestion.industry : '');
    }

    function getDataUrl() {
        const params = ticker !== '' ? `t=${encodeURIComponent(ticker)}` :
            `s=${encodeURIComponent(sector)}&i=${encodeURIComponent(industry)}`;
        return `${process.env.REACT_APP_SERVER_ENDPOINT}/api/t2?${params}`;
    }

    return (
        <div>
            <div className={"filter center"}>
                <form className="form-inline">
                    <div>
                        <TickerSearch
                            suggestions={tickers}
                            handleTickerSelected={handleTickerSelected}
                        />
                    </div>
                    <div>
                        <label htmlFor="sector-selection">
                            Sector:
                        </label>
                        <select
                            id="sector-selection"
                            name="sector-selection"
                            value={sector}
                            onChange={sectorChanged}>
                            <option key={'AnySector'} value={''}>{'-- Any Sector --'}</option>
                            {Object.keys(sectors).sort().map((sec: string) =>
                                <option key={sec} value={sec}>{sec}</option>
                            )}
                        </select>
                        <label htmlFor="industry-selection">
                            Industry:
                        </label>

                        <select
                            id="industry-selection"
                            value={industry}
                            onChange={industryChanged}>
                            <option key={'AnyIndustry'} value={''}>{'-- Any Industry --'}</option>
                            {sectors[sector] && sectors[sector].sort().map((ind: string) =>
                                <option key={ind} value={ind}>{ind}</option>
                            )}
                        </select>
                    </div>
                    <ColorCodes/>
                </form>
            </div>
            <Viewer getDataUrl={getDataUrl}
                    setTicker={setTicker}
                    setSector={setSector}
                    setIndustry={setIndustry}/>
        </div>
    )
}
