import React from 'react';

export default function () {
    return (
        <header>
            <h1>Stock<span style={{color: "red"}}>Recom.</span></h1>
            <h4 className={"subtitle"}>Stock Market Recommendation System</h4>
        </header>
    )
}
